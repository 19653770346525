import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PreviewComplex from "../components/previewComplex"
import SEO from "../components/seo"
import styles from "./index.module.scss"

const Writing = ({ data }) => {
  return (
    <Layout>
      <SEO />
      <ul className={styles.posts}>
        {data.allMarkdownRemark.edges.map((edge) => (
          <PreviewComplex
            type="writing"
            post={edge}
            key={edge.node.fields.slug}
          />
        ))}
      </ul>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(writing)/" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 100
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMM. DD, YYYY")
            verticalPosition
            order
          }
          timeToRead
          wordCount {
            words
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default Writing
